import React from "react"
import { Waypoint } from 'react-waypoint'
import styles from "./scss/index.module.scss"
import { graphql } from 'gatsby'
import {
  getHowItWorksProps,
  getContactUsProps,
  getBackgroundBlockProps,
  getFaqProps,
  getPricingProps,
} from "./props"
import {
  HowItWorks,
  ContactUs,
  Navigation,
  FirstBlock,
  SecondBlock,
  ThirdBlock,
  FourthBlock,
  PricingTableBlock,
  FAQ,
} from './components'

export default class Page extends React.Component {
  constructor(props) {
    super(props)
    this.state = { onWayPoint: 'home' }
  }
  onWayPointEnter(id) {
    this.setState({ onWayPoint: id })
  }
  onWayPointLeave(id) {
    if(this.state.onWayPoint === id) {
      this.setState({ onWayPoint: undefined })
    }
  }
  setWayPoint(element, id) {
    return (
      <Waypoint
        onEnter={() => this.onWayPointEnter(id)}
        onLeave={() => this.onWayPointLeave(id)}
      >
        <div>
          {element}
        </div>
      </Waypoint>
    )
  }
  render() {
    const props = this.props
    const entries = props.data.allContentfulPage.edges[0].node
    const firstBlockProps = getBackgroundBlockProps(entries.firstBlock)
    const secondBlockProps = getBackgroundBlockProps(entries.secondBlock)
    const thirdBlockProps = getBackgroundBlockProps(entries.thirdBlock)
    const fourthBlockProps = getBackgroundBlockProps(entries.fourthBlock)
    const faqProps = getFaqProps(entries.faq)
//    const serviceProps = getServiceProps(entries.services)
    const howItWorksProps = getHowItWorksProps(entries.howItWorks)
    const contactUsProps = getContactUsProps(entries.contactUs)
    const pricingProps = getPricingProps(entries.pricing)
    const naviProps = entries.navigation

    return (
      <div className={styles.frontPage}>
        {naviProps && <Navigation {...naviProps} onWayPoint={this.state.onWayPoint}/>}
        {this.setWayPoint(
          <FirstBlock {...firstBlockProps} />,
          firstBlockProps.scrollToId,
        )}
        <PricingTableBlock {...pricingProps}/>
        <SecondBlock {...secondBlockProps} />
        <HowItWorks {...howItWorksProps} />
        <ThirdBlock {...thirdBlockProps} />
        <FAQ {...faqProps} />
        <FourthBlock {...fourthBlockProps} />
        <ContactUs {...contactUsProps} />
      </div>
    )
  }
}

export const link = graphql`
  fragment link on ContentfulLink {
    label
    url
    icon
    inPageNavigationLink
  }
`

export const navigation = graphql`
  fragment navigation on ContentfulNavigation { 
    items {
      ...link
    }
  }
`

export const pricing = graphql`
  fragment pricing on ContentfulPricing {
    freeTitle
    premiumTitle
    scoutTitle
    freeFeatures
    premiumFeatures
    scoutFeatures
    freePrice
    premiumPrice
    scoutPrice
    freeLink {
      label
      url
    }
    premiumLink {
      label
      url
    }
    scoutLink {
      label
      url
    }
    description {
      content {
        content {
          value
        }
      }
    }
    links {
      ...link
    }
    scrollToId
  }
`

export const firstBlock = graphql`
  fragment firstBlock on ContentfulBackgroundBlock {
    backgroundImage {
      file {
        url
      }
    }
    scrollToId
    callToAction {
      ...link
    }
    secondCallToAction {
      ...link
    }
  }
`

export const secondBlock = graphql`
  fragment secondBlock on ContentfulBackgroundBlock {
    backgroundImage {
      file {
        url
      }
    }
  }
`

export const thirdBlock = graphql`
  fragment thirdBlock on ContentfulBackgroundBlock {
    backgroundImage {
      file {
        url
      }
    }
  }
`

export const fourthBlock = graphql`
  fragment fourthBlock on ContentfulBackgroundBlock {
    backgroundImage {
      file {
        url
      }
    }
  }
`

export const faq = graphql`
  fragment paragraph on ContentfulGeneralPageContent {
    paragraph {
      paragraph
    }
    scrollToId
  }
`


export const services = graphql`
  fragment services on ContentfulServicesWhatSailmateOffers {
    content {
      paragraph {
        paragraph
      }
    }
    options {
      paragraph {
        paragraph
      }
    }
    disclaimer {
      disclaimer
    }
    scrollToId
  }
`

export const howItWorks = graphql`
  fragment howItWorks on ContentfulHowItWorks {
    description {
      description
    }
    links {
      ...link
    }
  }
`

export const contactUs = graphql`
  fragment contactUs on ContentfulContactUs {
    heading
    contactLinks {
      ...link
    }
    scrollToId
  }
`

export const contentfulPage = graphql`
  fragment contentfulPage on ContentfulPage {
    navigation {
      ...navigation
    }
    pricing {
      ...pricing
    }
    firstBlock {
      ...firstBlock
    }
    secondBlock {
      ...secondBlock
    }
    thirdBlock {
      ...thirdBlock
    }
    fourthBlock {
      ...fourthBlock
    }
    faq {
      ...paragraph
    }
    services {
      ...services
    }
    howItWorks {
      ...howItWorks
    }
    contactUs {
      ...contactUs
    }
  }
`
