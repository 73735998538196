import React from  'react'
import {PricingTable, PricingSlot, PricingDetail} from 'react-pricing-table'
import styles from "./scss/index.module.scss"
import Markdown from 'markdown-to-jsx'
import './fonts/fontawesome-all.js'
import classNames from 'classnames'
import scrollToElement from 'scroll-to-element'

export class Navigation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      naviOpen: false
    }
  }
  render() {
    const onWayPoint = this.props.onWayPoint
    return (
      <div
        className={classNames(styles.navigationContainer, {
          [styles.navigationContainerBackground]: onWayPoint !== 'home'
        })
      }>
        <div className={styles.navigation}>
          <p className={styles.logo}><span>a</span>SAILMATE</p>
          <div className={styles.desktopMenuItems}>

            {this.props.items.map((item, index) => (
              <a
                key={index}
                href={item.url && item.url}
                className={styles.naviLink}
                onClick={() => item.inPageNavigationLink && scrollToElement(item.url)}
              >
                {item.label && item.label}
                {item.icon && <i className={`fab ${item.icon}`} />}
              </a>
            ))}
          </div>
          <button
            className={styles.mobileMenu}
            onClick={() => this.setState({ naviOpen: !this.state.naviOpen })}
          >
            <i className="fas fa-bars fa-lg"></i>
          </button>
        </div>
        {this.state.naviOpen && (
          <div className={styles.mobileMenuItems}>
            {this.props.items.map((item, index) => (
              <a
                key={index}
                href={item.url && item.url}
                className={styles.naviLink}
                onClick={() => {
                  this.setState({ naviOpen: !this.state.naviOpen })
                  item.inPageNavigationLink && scrollToElement(item.url)
                }}
              >
                {item.label && item.label}
                {item.icon && <i className={`fab ${item.icon} fa-lg`} />}
              </a>
            ))}
          </div>
        )}
      </div>
    )
  }
}

export const FirstBlock = props => (
  <div
    className={styles.heroBlock}
    style={{ backgroundImage: `url(${props.backgroundImage.file.url})`}}
    id={props.scrollToId}
    ref={props.innerRef}
  >
    <h1 className={styles.heading}>Nautics <span className={styles.color}>Sailmate</span></h1>
    <a href={props.callToAction.url} className={styles.getPremiumLink}>{props.callToAction.label}</a>
    <a href={props.secondCallToAction.url} className={styles.getPremiumLink}>{props.secondCallToAction.label}</a>
  </div>
)

export const SecondBlock = props => (
  <div
    className={styles.heroBlock}
    style={{ backgroundImage: `url(${props.backgroundImage.file.url})`}}
    id={props.scrollToId}
    ref={props.innerRef}
  />
)

export const ThirdBlock = props => (
  <div
    className={styles.heroBlock}
    style={{ backgroundImage: `url(${props.backgroundImage.file.url})`}}
    id={props.scrollToId}
    ref={props.innerRef}
  />
)

export const FourthBlock = props => (
  <div
    className={styles.heroBlock}
    style={{ backgroundImage: `url(${props.backgroundImage.file.url})`}}
    id={props.scrollToId}
    ref={props.innerRef}
  />
)

export const PricingTableBlock = props => (
  <div className={[styles.scrollingBg, styles.cdScrollingBgColor2].join(' ')} id={props.scrollToId && props.scrollToId}>
    <div className={styles.scrollingBgContent}>
      <Markdown>{props.description.content[0].content[0].value}</Markdown>
      <div className={styles.downLoadLinks}>
        {props.links && props.links.map((link, i) => (
          <a key={i} href={link.url} className={styles.downLoadLink}>
          {link.icon && <i className={`fab ${link.icon} fa-lg`} />}
          {link.label}
          </a>
        ))}
      </div>
      <div style={{marginTop: '20px'}}>
        <PricingTable highlightColor='#1976D2'>
          <PricingSlot onClick={() => window.location = props.freeLink.url} buttonText={props.freeLink.label} title={props.freeTitle} priceText={props.freePrice}>
            {props.freeFeatures && props.freeFeatures.map((feature, i) => (
              <div key={i} style={{visibility: feature === '..' ? 'hidden' : 'visible'}}>
                <PricingDetail key={i}>{feature}</PricingDetail>
              </div>
            ))}
          </PricingSlot>
          <PricingSlot onClick={() => window.location = props.premiumLink.url} buttonText={props.premiumLink.label} title={props.premiumTitle} priceText={props.premiumPrice}>
            {props.premiumFeatures && props.premiumFeatures.map((feature, i) => (
              <PricingDetail key={i}>{feature}</PricingDetail>
            ))}
          </PricingSlot>
          <PricingSlot onClick={() => window.location = props.scoutLink.url} buttonText={props.scoutLink.label} title={props.scoutTitle} priceText={props.scoutPrice}>
            {props.scoutFeatures && props.scoutFeatures.map((feature, i) => (
              <div key={i} style={{visibility: feature === '..' ? 'hidden' : 'visible'}}>
                <PricingDetail key={i}>{feature}</PricingDetail>
              </div>
            ))}
          </PricingSlot>
        </PricingTable>
      </div>
    </div>
  </div>
)

export const Services = props => (
  <div className={[styles.scrollingBg, styles.cdScrollingBgColor2].join(' ')} id={props.scrollToId && props.scrollToId}>
    <div className={styles.scrollingBgContent}>
      <div className={styles.serviceContent}>
        <div className={styles.serviceContentContainer}>
          <Markdown>{props.content.paragraph.paragraph && props.content.paragraph.paragraph}</Markdown>
        </div>
      </div>
      <div className={styles.serviceOptions}>
        {props.options && props.options.map((option, i) => (
          <div key={i} className={styles.serviceOption}>
            <Markdown>{option.paragraph.paragraph && option.paragraph.paragraph}</Markdown>
          </div>
        ))
        }
      </div>
      <div className={styles.servicesDisclaimer}>
        <Markdown>{props.disclaimer.disclaimer && props.disclaimer.disclaimer}</Markdown>
      </div>
    </div>
  </div>
)

export const FAQ = props => (
  <div className={[styles.scrollingBg, styles.cdScrollingBgColor2].join(' ')} id={props.scrollToId && props.scrollToId}>
    <div className={styles.scrollingBgContent}>
      <Markdown>{props.paragraph.paragraph && props.paragraph.paragraph}</Markdown>
    </div>
  </div>
)

export const HowItWorks = props => (
  <div className={[styles.scrollingBg, styles.cdScrollingBgColor2].join(' ')}>
  <div className={styles.scrollingBgContent}>
    {(props.description && props.description.description) && <Markdown>{props.description.description}</Markdown>}
    <div className={styles.downLoadLinks}>
    {props.links && props.links.map((link, i) => (
      <a key={i} href={link.url} className={styles.downLoadLink}>
      {link.icon && <i className={`fab ${link.icon} fa-lg`} />}
      {link.label}
      </a>
    ))}
    </div>
  </div>
</div>
)

export const ContactUs = props => (
  <div className={styles.contactUs} id={props.scrollToId}>
    {props.heading && <h2>{props.heading}</h2>}
    <div className={styles.contactLinks}>
    {props.contactLinks && props.contactLinks.map((link, i) => (
      <div key={i}>
        <i className={link.icon} />
        <a key={i} href={link.url} className={styles.contactLink}>{link.label}</a>
      </div>
    ))}
    </div>
  </div>
)
