export function getHeroProps(entries) {
  const {
    heading,
    label,
    backgroundImage,
    mapPlanningLink,
    getPremiumLink,
    getScoutLink,
    scrollToId
  } = entries
  return {
    heading,
    label,
    backgroundImage,
    mapPlanningLink,
    getPremiumLink,
    getScoutLink,
    scrollToId
  }
}

export function getPricingProps(entries) {
  const {
    freeTitle,
    premiumTitle,
    scoutTitle,
    freeFeatures,
    premiumFeatures,
    scoutFeatures,
    freePrice,
    premiumPrice,
    scoutPrice,
    freeLink,
    premiumLink,
    scoutLink,
    description,
    links,
    scrollToId
  } = entries
  return {
    freeTitle,
    premiumTitle,
    scoutTitle,
    freeFeatures,
    premiumFeatures,
    scoutFeatures,
    freePrice,
    premiumPrice,
    scoutPrice,
    freeLink,
    premiumLink,
    scoutLink,
    description,
    links,
    scrollToId
  }
}

export function getBackgroundBlockProps(entries) {
  const {
    backgroundImage,
    scrollToId,
    callToAction,
    secondCallToAction
  } = entries
  return {
    backgroundImage,
    scrollToId,
    callToAction,
    secondCallToAction
  }
}

export function getFaqProps(entries) {
  const {
    paragraph,
    scrollToId
  } = entries
  return {
    paragraph,
    scrollToId
  }
}

export function getServiceProps(entries) {
  const {
    content,
    options,
    disclaimer,
    scrollToId,
  } = entries
  return {
    content,
    options,
    disclaimer,
    scrollToId
  }
}

export function getHowItWorksProps(entries) {
  const {
    description,
    links,
  } = entries

  return {
    description,
    links,
  }
}

export function getContactUsProps(entries) {
  const {
    heading,
    contactLinks,
    scrollToId
  } = entries

  return {
    heading,
    contactLinks,
    scrollToId
  }
}
